<template>
  <v-autocomplete
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    color="tertiary"
    :label="$t('hr.training.training')"
    item-value="id"
    item-text="name"
    :menu-props="{ offsetY: true, overflowY: true }"
    :loading="loading || fetching"
    :items="items"
    :clearable="clearable"
    @input="$emit('input', $event)"
  >
    <template #item="{ item }">
      <v-list-item-avatar size="28" :color="item.color">
        <span class="white--text body-1 font-weight-medium">
          {{ item.name.substring(0, 2).toUpperCase() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
  import gql from "graphql-tag";

  export default {
    name: "TrainingPicker",
    inheritAttrs: false,
    props: {
      value: String,
      loading: Boolean,
      clearable: {
        type: Boolean,
        default: true
      }
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    methods: {
      fetchItems() {
        const options = {
          query: gql`
            {
              trainings {
                id
                name
                color
              }
            }
          `,
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { trainings, error }, errors }) => {
            if (!error && !errors) {
              this.items = trainings;
            }
          })
          .catch(console.error)
          .finally(() => (this.fetching = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>
